import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Toggle from '../../components/Toggle';
import AuthContext from '../../contexts/AuthContext';
import TS from '../../api';

const Row = (props) => {
  const { title, initialValue, callback, children } = props;
  return (
    <div className="flex py-3 border-t first:border-t-0 p-3 sm:p-4">
      <div className="w-4/5">
        <p className="leading-5">
          {title}
        </p>
        <p className="text-xs text-gray-600">{children}</p>
      </div>
      <div className="w-1/5 flex justify-end items-center">
        <Toggle initial={initialValue} callback={callback} />
      </div>
    </div>
  );
};

export default function Notifications() {
  const { session, update: updateSession } = useContext(AuthContext);
  const profile = session?.data?.profile;

  const toggleCallback = (val, field) => {
    TS.put(`/user_profiles/${profile.id}`, {
      ...profile,
      [field]: val
    }).then(() => {
      updateSession((prev) => {
        const ret = Object.assign({}, prev);
        ret.data.profile[field] = val;
        return ret;
      });
    });
  };

  return (
    <div className="mx-auto max-w-screen-lg pt-6 pb-12 px-4 lg:px-0">
      <header>
        <h1 className="font-normal text-2xl">Manage Notifications</h1>
        <p className="text-sm">
          Control the types of notifications you will receive from Tunespeak.
        </p>
      </header>
      <div className="bg-white overflow-hidden rounded-sm shadow-sm mt-6">
        <Row
          title="Mobile Notifications"
          initialValue={profile.phone_enabled}
          callback={(val) => toggleCallback(val, 'phone_enabled')}
        >
          <span>
            Enable or disable all SMS mobile notifications about promotions,
            offers, etc. from Tunespeak
          </span>
          <br></br>
          <span className="text-xs">
            * Reply STOP to stop getting text messages about presales you've
            registered for with your mobile number
          </span>
        </Row>

        <Row
          title="Email Notifications"
          initialValue={profile.email_enabled}
          callback={(val) => toggleCallback(val, 'email_enabled')}
        >
          <span>Enable or disable all email notifications</span>
          <br></br>
          <span className="text-xs">
            * you will always be notified if you win
          </span>
        </Row>

        {profile.email_enabled && (
          <Fragment>
            <Row
              title="Weekly Summary"
              initialValue={profile.email_weekly_summary}
              callback={(val) => toggleCallback(val, 'email_weekly_summary')}
            >
              <span>A weekly status report of your current campaigns.</span>
            </Row>

            <Row
              title="Campaign Reminders"
              initialValue={profile.email_campaign_reminder}
              callback={(val) => toggleCallback(val, 'email_campaign_reminder')}
            >
              <span>Reminders, updates, and tips to help you win.</span>
            </Row>

            <Row
              title="Favorite Artists"
              initialValue={profile.email_favorite_bands}
              callback={(val) => toggleCallback(val, 'email_favorite_bands')}
            >
              <span>
                Get notified when artists you favorited launch new campaigns.
              </span>
            </Row>

            <Row
              title="Artist Messages"
              initialValue={profile.email_band_message}
              callback={(val) => toggleCallback(val, 'email_band_message')}
            >
              <span>
                Receive an email when an artist messages you directly through
                Tunespeak.
              </span>
              {profile.email_band_message && (
                <Link
                  to="/settings/artist-messages"
                  className="link-blue block"
                >
                  Manage per-artist notifications{' '}
                  <i className="icon-arrow-right"></i>
                </Link>
              )}
            </Row>
          </Fragment>
        )}
      </div>
    </div>
  );
}
